import dynamic from 'next/dynamic';
import cn from 'classnames';

const Spinner = dynamic(() => import('./Spinner'));

export type SpinnerSize = 'small' | 'middle' | 'regular';

export interface LoaderProps extends React.HTMLAttributes<HTMLDivElement> {
  fade?: boolean;
  size?: SpinnerSize;
  label?: React.ReactNode;
  loading: boolean;
  fixed?: boolean;
  height?: number | string;
}

const Loader: React.FC<LoaderProps> = ({
  fade = true,
  fixed,
  size = 'regular',
  label,
  loading,
  height = 350,
  children,
  className,
  ...props
}) => (
  <div
    className={cn('loader', className)}
    style={{ minHeight: loading ? height : undefined }}
    {...props}
  >
    {loading && label && <div className="loader-label">{label}</div>}
    <Spinner fixed={fixed} size={size} className={!loading ? 'hide' : ''} />

    {fade ? (
      <div className="fade-in" style={{ display: loading ? 'none' : undefined }}>
        {children}
      </div>
    ) : !loading ? (
      children
    ) : null}
  </div>
);

export default Loader;
